/* Copyright (C) 2019 Cineplex Digital Media, Inc. All Rights Reserved. */
import React, { Component } from 'react'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from 'react-share';
import config from './config'

class Results extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      title: null,
      shortDesc: null,
      longDesc: null,
      image: null,
      displayShare: false,
      isLoaded: false
    }
  }

  componentDidMount() {
    this.setState({ 
      title: localStorage.getItem('resultType'),
      image: localStorage.getItem('resultImage'),
      shortDesc: localStorage.getItem('shortDesc'),
      longDesc: localStorage.getItem('longDesc'),
      isLoaded: true
    })
  }

  render() {
    const { isLoaded, title, image, shortDesc, longDesc, displayShare, error } = this.state
    if(!isLoaded) {
      return (
        <div className="standardPage">
          <img className="logo" alt="Logo" src="images/logo.png" />
        </div>
      )
    } else if(error) {
      return (
        <div className="standardPage">
          <img className="logo" alt="Logo" src="images/logo.png" />
          <p className="error">{error}</p>
        </div>
      )
    } else {
      const imagePath = config.image_url + image
      const htmlPath = config.image_url + image.replace(/\.jpg/,'') + '.html'
      const quote = 'I am a ' + title + '. Find out what you are made of at citizensmadereadyquiz.com @citizensbank #madeready'
      const hashtag = '#madeready'

      return (
        <div className="standardPage">
          <img className="logo" alt="Logo" src="images/logo.png" />
          <div>
            <p className="resultsTitle darkGreen">You're {title === 'advocate' ? 'an' : 'a'}<br /><span className="larger">{title}</span></p>
            <p className="resultsShort darkGreen">{shortDesc}</p>
            <p className="resultsLong">{longDesc}</p>
          </div>
          <div className="resultsBottom">
            <img className="mantra" alt="Your Mantra" src="images/mantra.png" />
            <img className="card" alt="Your Card" src={imagePath} />
            <img alt="Email" src="images/email.png" />
            <p className="email lightGreen">Check your email for a shareable copy of your results, including your personal mantra image for social media.</p>
            <img onClick={() => this.setState({displayShare: true})} className="button" alt="share" src="images/share_button.png" />
          </div>
          {displayShare && <div className="shareBox">
            <div className="shareContain" onClick={() => this.setState({displayShare: false})}>
              <p>X</p>
              <LinkedinShareButton url={htmlPath}><img alt="LinkedIn" src="images/linkedin.png" /></LinkedinShareButton>
              <FacebookShareButton url={htmlPath} quote={quote} hashtag={hashtag}><img alt="Facebook" src="images/facebook.png" /></FacebookShareButton>
              <TwitterShareButton url={htmlPath} title={quote} hashtags={[hashtag]}><img alt="Twitter" src="images/twitter.png" /></TwitterShareButton>
            </div>
          </div>}
        </div>
      )
    }
  }
}

export default Results
