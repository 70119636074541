/* Copyright (C) 2019 Cineplex Digital Media, Inc. All Rights Reserved. */
/* eslint-disable */
import React, { Component } from 'react'
import config from './config'
import {Helmet} from 'react-helmet'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: null,
      email: null,
      check: null,
      accepted: false,
      errorM: null
    }
  }

  
  nextStep() {
    const { name, email, check, accepted } = this.state
    if(email==null && name==null){
      return this.setState({ errorM: "Please enter name and email" });
    }else if(name==null || name == ''){
      return this.setState({ errorM: "Please enter name" });
    }else if(!name.match(/^[A-Za-z\s]+$/)){
      return this.setState({ errorM: "Name must contain only letters" });
    }else if(email==null){
      return this.setState({ errorM: "Please enter valid email" });
    }else if(email !== check){
      return this.setState({ errorM: "Please ensure your email matches" });
    }else if(!accepted) {
      return this.setState({ errorM: "Please review and accept terms" })
    }

    let emailValid = false;
    emailValid = email.match(/^([\w\'.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    
    if(emailValid!=null){
        fetch(config.server, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            method: 'startSession',
            parameters: {
            name: name,
            email: email
            }
        })
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject(response);
    })
    .then((result) => {
      //TODO: status checking
      const sessionId = result.return
      localStorage.setItem('session_id', sessionId)
      this.props.history.push('/Photo')
    })
    .catch((error) => {
      if(error.json){
        error.json().then((err) => {
          return this.setState({ errorM: err.message });
        })
      }
    })
    }else{
        this.setState({ errorM: "Please Enter Valid Email!" });
    }
  }

  render() {
    const { accepted } = this.state

    return (
      <div className="loginPage">
        <Helmet>
          <style>{'body { background-color: #00945f; }'}</style>
        </Helmet>
        <img className="introImage" alt="Intro" src="images/start_page.png" />
          <div>
            <p>Name</p>
            <input type="text" placeholder="Please enter your name" onChange={(event) => this.setState({ name: event.target.value })} />
          </div>
          <div>
            <p>Email</p>
            <input type="email" placeholder="Please enter your email" onChange={(event) => this.setState({ email: event.target.value })} />
          </div>
          <div>
            <p>Re-Enter Email</p>
            <input type="email" placeholder="Please re-enter your email" onChange={(event) => this.setState({ check: event.target.value })} />
          </div>
          <div id="agree" onClick={() => this.setState({accepted: !accepted})}>
            <input readOnly type="checkbox" checked={accepted} />I acknowledge the <a href="https://www.citizensbank.com/account-safeguards/privacy.aspx">online privacy policy</a>
          </div>
          <label type="text" onChange={(event) => this.setState({ errorM: event.target.value })} value={this.state.errorM}>{this.state.errorM}</label>
          <img className="button" alt="Start" onClick={() => this.nextStep()} src="images/start_button.png" />
      </div>
    )
  } 
}

export default Login
