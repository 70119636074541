/* Copyright (C) 2019 Cineplex Digital Media, Inc. All Rights Reserved. */
import React, { Component } from 'react'
import config from './config'
import {isIE} from 'react-device-detect'

class Questions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      currentQuestion: 0,
      answers: [],
      questions: [],
      isLoaded: false
    }
  }

  componentDidMount() {
    fetch(config.server)
    .then(response => response.json())
    .then(data => {
      if(!data || !data.status) {
        this.setState({ isLoaded: true, error: 'Unable to retrieve the questions.' })
      } else {
        this.setState({ isLoaded: true, questions: data.return })
      }
    })
  }

  answer(id) {
    const { answers, currentQuestion, questions } = this.state
    const nextQuestion = currentQuestion + 1
    const sessionId = localStorage.getItem('session_id')
    answers[currentQuestion] = id

    fetch(config.server, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        method: 'postAnswer',
        parameters: {
          session_id: sessionId,
          question_id: questions[currentQuestion].id,
          answer_id: id
        }
      })
    })
    .then((response) => response.json())
    .then((result) => {
      //TODO: status checking
      if(nextQuestion >= questions.length) {
        this.setState({ answers: answers })
        fetch(config.server, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            method: 'complete',
            parameters: {
              session_id: sessionId
            }
          })
        })
        .then((response) => response.json())
        .then((result) => {
          //TODO: status checking
          localStorage.setItem('shortDesc', result.return.short_description)
          localStorage.setItem('longDesc', result.return.long_description)
          localStorage.setItem('resultImage', result.return.image)
          localStorage.setItem('resultType', result.return.result_type)
          this.props.history.push('/Results')
        })
      } else {
        this.setState({ currentQuestion: nextQuestion, answers: answers})
      }
    })
  }

  back() {
    const { currentQuestion } = this.state
    if(currentQuestion === 0) {
      this.props.history.push('/Photo')
    } else {
      this.setState({ currentQuestion: currentQuestion - 1 })
    }
  }

  render() {
    const { isLoaded, error, currentQuestion, questions } = this.state
    if(!isLoaded) {
      return (
        <div className="standardPage">
          <img className="logo" alt="Logo" src="images/logo.png" />
        </div>
      )
    } else if(error) {
      return (
        <div className="standardPage">
          <img className="logo" alt="Logo" src="images/logo.png" />
          <p className="error">{error}</p>
        </div>
      )
    } else {
      return (
        <div className="standardPage">
          <img className="logo" alt="Logo" src="images/logo.png" />
          <div className={isIE ? 'smallerFont' : ''}>
            <p className="position grey">{currentQuestion + 1} / {questions.length}</p>
            {currentQuestion === 0 && <p className="description grey">Choose the answer that best decribes you</p>}
            <p className="question darkGreen">{questions[currentQuestion].text}</p>
            <table className="answers lightGreen">
              <tbody>
                <tr>
                  <td id="one" onClick={() => this.answer(questions[currentQuestion].answers[0].id)}>{questions[currentQuestion].answers[0].text}</td>
                  <td id="two" onClick={() => this.answer(questions[currentQuestion].answers[1].id)}>{questions[currentQuestion].answers[1].text}</td>
                </tr>
                <tr>
                  <td id="three" onClick={() => this.answer(questions[currentQuestion].answers[2].id)}>{questions[currentQuestion].answers[2].text}</td>
                  <td id="four"  onClick={() => this.answer(questions[currentQuestion].answers[3].id)}>{questions[currentQuestion].answers[3].text}</td>
                </tr>
              </tbody>
            </table>
            <img className="button" alt="Back" src="images/back.png" onClick={() => this.back()} />
          </div>
        </div>
      )
    }
  }
}

export default Questions
