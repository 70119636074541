/* Copyright (C) 2019 Cineplex Digital Media, Inc. All Rights Reserved. */
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Login from './Login'
import Photo from './Photo'
import Questions from './Questions'
import Results from './Results'

class App extends Component {
  render() {
    return (
      <div className="appBody">
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/photo" component={Photo} />
          <Route exact path="/questions" component={Questions} />
          <Route exact path="/results" component={Results} />
          <Route component={Login} />
        </Switch>
      </div>
    )
  } 
}

export default App
