/* Copyright (C) 2019 Cineplex Digital Media, Inc. All Rights Reserved. */
import React, { Component } from 'react'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import config from './config'
import {isIOS, isIE} from 'react-device-detect'

class Photo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      photoSource: 'none',
      photoImage: null,
      width: 512,
      height: 288,
      crop: {
        aspect: 370 / 720,
        x: 0,
        y: 0,
        height: 288
      }
    }
  }

  nextStep() {
    const { photoSource, photoImage, crop, width, height } = this.state

    if(photoSource === 'none') {
      localStorage.setItem('image_name', '')
      this.props.history.push('/Questions')
    } else if(crop.width < 10) {
      this.setState({crop: { x: 0, y: 0, height: 288 }});
    } else {
      if(window.confirm('Have you selected the portion of the image you would like displayed?')) {
        fetch(config.server, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            method: 'postPhoto',
            parameters: {
              session_id: localStorage.getItem('session_id'),
              photo: photoImage,
              width: width,
              height: height,
              crop: crop
            }
          })
        })
        .then((response) => response.json())
        .then((result) => {
          //TODO: status checking
          const imageName = result.return
          localStorage.setItem('image_name', imageName)
          this.props.history.push('/Questions')
        })
      }
    }
  }

  /* Process pictures taken through page and the react-html5-camera-photo library */
  onTakePhoto(dataUri) {
    this.setState({ photoImage: dataUri })
  }

  changeMode(mode) {
    if(mode === 'photo') {
      this.refs.hiddenInput.click();
    }
    this.setState({ photoSource: mode, photoImage: null })
  }

  handleFileChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      fetch(config.server, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          method: 'correctPhoto',
          parameters: {
            session_id: localStorage.getItem('session_id'),
            photo: reader.result
          }
        })
      })
      .then((response) => response.json())
      .then((result) => {
        const imageData = result.return
        this.setState({
          processing: false,
          photoSource: 'photo',
          photoImage: imageData
        })
      })
    }

    if(file) { 
      reader.readAsDataURL(file)
    }
  }

  render() {
    const { processing, photoSource, photoImage, crop } = this.state
    let cameraSection

    /* Help the user along in their photo select */
    if(processing) {
      cameraSection =
        <div className="happyFace">
          <p>Please wait, processing your photo now</p>
        </div>
    } else if(photoImage && isIE) {
      cameraSection = <img alt="You!" style={{width: '100%'}} src={photoImage} />
    } else if(isIE) {
      cameraSection =
        <div className="happyFace">
          <p>We're sorry. We're unable to capture your photo with the older version of Internet Explorer. Using the more recent version 'Edge' or another browser will give you image options!</p>
          <br/><br />
          <p>If you'd like to upload an existing image, click on Browse.</p>
          <input className="fileInput" type="file" accept=".jpg,.jpeg" onChange={(e)=>this.handleFileChange(e)} />
        </div>
    } else if(photoImage) {
      cameraSection = <div>
        <ReactCrop minWidth={10} minHeight={10} className="photoImage" src={photoImage} crop={crop} onChange={newCrop => this.setState({crop: newCrop})} onImageLoaded={image => this.setState({ width: image.width, height: image.height })} />
        <p style={{color: 'orange',fontSize: 'larger'}}>Please adjust the box above around your headshot and select Start Quiz when ready to proceed</p>
      </div>
    } else if(photoSource === 'camera') {
      cameraSection = <Camera
        onTakePhoto = { (dataUri) => { this.onTakePhoto(dataUri) } }
        imageType = {IMAGE_TYPES.JPG}
        imageCompression = {0.97}
        isMaxResolution = {true}
        isImageMirror = {false}
        isSilentMode = {true}
        isDisplayStartCameraError = {true}
        idealFacingMode = {FACING_MODES.USER}
        sizeFactor = {1}
      />
    } else {
      cameraSection =
        <div className="happyFace">
          <img alt="Happy Face" src="images/happy_face.png" />
          <p>Add your photo to personalize<br />your quiz results.</p>
        </div>
    }

    let width = {width: '22em'}
    if(isIOS) {
      width = {width: '14em'}
    } else if(isIE) {
      width = {width: '6em'}
    }

    return (
      <div className="standardPage">
        <img className="logo" alt="Logo" src="images/logo.png" />
        <div className="cameraSection">
          {cameraSection}
        </div>
        <div className="photoSelection" style={width}>
          {!isIOS && !isIE && <div className={photoSource === 'camera' ? 'selected' : 'unselected'} onClick={() => this.changeMode('camera')}>
            <img alt="Camera" src="images/camera.png" />
            <p>Take photo</p>
          </div>}
          {!isIE && <div className={photoSource === 'photo' ? 'selected' : 'unselected'} onClick={() => this.changeMode('photo')}>
            <img alt="Choose" src="images/photo.png" />
            <p>{isIOS ? 'Choose or Take' : 'Choose'} photo</p>
            <input id="hiddenInput" ref="hiddenInput" style={{display: 'none'}} type="file" accept=".jpg,.jpeg" onChange={(e)=>this.handleFileChange(e)} />
          </div>}
          <div className={photoSource === 'none' ? 'selected' : 'unselected'} onClick={() => this.changeMode('none')}>
            <img alt="None" src="images/no_photo.png" />
            <p>No photo</p>
          </div>
        </div>
        <img className="button" alt="Start" onClick={() => this.nextStep()} src="images/start_button.png" />
      </div>
    )
  } 
}

export default Photo
