/* Copyright (C) 2019 Cineplex Digital Media, Inc. All Rights Reserved. */
/*
export default {
  "dserver": "https://red.tech.ek3.com/quiz_service/service.php",
  "dimage_url": "https://red.tech.ek3.com/quiz_service/",
  "server": "https://citizensmadereadyquiz.com/service/service.php",
  "image_url": "https://citizensmadereadyquiz.com/service/"
}
*/

export default {
  "dserver": "https://citizensmadereadyquiz.com/quiz_service/service.php",
  "dimage_url": "https://citizensmadereadyquiz.com/quiz_service/",
  "server": "https://citizensmadereadyquiz.com/quiz_service/service.php",
  "image_url": "https://citizensmadereadyquiz.com/quiz_service/"
}
